<template>
  <div class="main">
      <div class="banner">

          <div class="left">
              <canvas id="canvas1" ref="canvas1" width="711" height="258">

              </canvas>
          <div class="top">
              <div class="logo">
                  <img src="../assets/logo.png"/>
              </div>
              <div class="navs">
                  <div v-for="(item,index) in navItemList" :key="index" :class="navItemClasses[index]" @mouseenter="showNavBorderBottom(index)" @mouseleave="hideNavBorderBottom">{{item}}</div>

              </div>

          </div>
          <div class="introduce1">
              <span :class="tumaStyle">图码</span>引领孩子进入&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="emphasize" id="codeCountry">编程王国</span>的世界
          </div>
          <div class="tip1">
              {{tip1}}
          </div>
          <div class="effectiveList">
              <div class="effectiveItem">
                  <div class="effectiveImg">
                      <img src="../assets/IntelliJ.png"/>
                  </div>
                  <div class="effectiveRightArea">
                      <div class="effectiveTxt">智慧</div>
                      <div class="effectiveTxt">IntelliJ</div>
                  </div>

              </div>
              <div class="effectiveItem">
                  <div class="effectiveImg">
                      <img src="../assets/Creative.png"/>
                  </div>
                  <div class="effectiveRightArea">
                      <div class="effectiveTxt">创造</div>
                      <div class="effectiveTxt">Creative</div>
                  </div>

              </div>

              <div class="effectiveItem">
                  <div class="effectiveImg">
                      <img src="../assets/Explore.png"/>
                  </div>
                  <div class="effectiveRightArea">
                      <div class="effectiveTxt">探索</div>
                      <div class="effectiveTxt">Explore</div>
                  </div>

              </div>
          </div>
          </div>

        <div class="right">
            <img src="../assets/bannerBg.png"/>
        </div>
      </div>
      <div class="title">
          我们的教育理念
      </div>
      <div class="educationBelief">
            <div :class="educationBeliefItemClassList[0]" @mouseenter="showBeliefDetails(0)" @mouseleave="hideBeliefDetails">

                    <div class="beliefImg" v-if="!isShowBelifDetailsList[0]">
                        <img src="../assets/scene.png"/>
                    </div>
                    <div class="beliefName" v-if="!isShowBelifDetailsList[0]">场景式教学</div>

                <div v-else>
                    每节课都以一个场景案例为背景，让孩子在案例的故事背景下能够更容易地理解吸收新的知识点并学会融汇贯通
                    以及应用。
                </div>

            </div>
          <div :class="educationBeliefItemClassList[1]" @mouseenter="showBeliefDetails(1)" @mouseleave="hideBeliefDetails">
              <div class="beliefImg" v-if="!isShowBelifDetailsList[1]">
                  <img src="../assets/light.png"/>
              </div>
              <div class="beliefName" v-if="!isShowBelifDetailsList[1]">启发式教学</div>
              <div v-else>
                  摒弃了传统的灌输式教学方式，我们的教学围绕发展每节课的课程目标而展开。由老师提出问题，然后老师主要
                  促进、帮助学生结合之前学过的知识以及实验验证等方式让学生通过独立和小组讨论等方式最终解决问题。
              </div>
          </div>


          <div :class="educationBeliefItemClassList[2]" @mouseenter="showBeliefDetails(2)" @mouseleave="hideBeliefDetails">
              <div class="beliefImg" v-if="!isShowBelifDetailsList[2]">
                  <img src="../assets/speakWithPractice.png"/>
              </div>
              <div class="beliefName" v-if="!isShowBelifDetailsList[2]">讲练结合</div>
              <div v-else>
                  我们采用讲练结合的方式，老师讲解完一个知识点之后让学生马上着手练习，达到及时消化吸收的效果，在学生练习完成之后
                  老师再继续下一个知识点的讲解。
              </div>
          </div>
      </div>
      <div class="significance" @mouseenter="showSignificanceTip">
          <canvas id="canvas2" ref="canvas2" width="1423" height="170"></canvas>
          <div class="significanceMain">
              <div class="significanceLeft">
                  <div class="significanceTitle">编程的<span class="significanceEmphasize">意义</span></div>
                  <div class="significanceContent">通过编程激发孩子的创造力和对未知世界的渴望</div>
              </div>
              <div class="significanceRight">
                  <div class="astronaut">
                      <img src="../assets/astronaut.png"/>
                  </div>
              </div>
          </div>


          <div class="significanceTip" v-if="isShowSignificanceTip">
                {{significanceTips}}
          </div>


      </div>
      <div class="title2">
          课程大纲
      </div>
      <div id="threejs"></div>

      <div class="courseList">
            <div class="graph">
                <div class="graphTitle1">
                     图形化编程
                </div>
                <div class="graphContentArea1">
                    <div class="graphContent">
                        掌握编程三个基础逻辑结构
                    </div>
                    <div class="graphContent">
                        熟练应用图形化编程代码块
                    </div>
                    <div class="graphContent">
                        建立完整的项目思维
                    </div>
                </div>

                <div class="graphContentArea1">
                    <div class="graphContent">
                        逻辑思维能力
                    </div>
                    <div class="graphContent">
                        动手操作能力
                    </div>
                </div>

                <div class="graphContentArea1">
                    <div class="graphContent">
                        YCL 1-3级
                    </div>
                    <div class="graphContent">
                        蓝桥杯
                    </div>
                    <div class="graphContent">
                        NOC等
                    </div>
                </div>

            </div>
            <div class="python">
                <div class="graphTitle2">
                    Python
                </div>
                <div class="graphContentArea2">
                    <div class="graphContent">
                        掌握Python编程语言
                    </div>
                    <div class="graphContent">
                        了解人工智能原理
                    </div>
                    <div class="graphContent">
                        建立算法和数据结构思维
                    </div>
                </div>

                <div class="graphContentArea2">
                    <div class="graphContent">
                        模式识别能力
                    </div>
                    <div class="graphContent">
                        应用迁移能力
                    </div>
                </div>

                <div class="graphContentArea2">
                    <div class="graphContent">
                        YCL 1-4级
                    </div>
                    <div class="graphContent">
                        蓝桥杯
                    </div>
                    <div class="graphContent">
                        NOC等
                    </div>
                </div>
            </div>
            <div class="cpp">
                <div class="graphTitle3">
                    C++
                </div>
                <div class="graphContentArea3">
                    <div class="graphContent">
                        系统掌握C++语法
                    </div>
                    <div class="graphContent">
                        深入了解核心编程概念
                    </div>
                    <div class="graphContent">
                        探索计算机高级算法
                    </div>
                </div>

                <div class="graphContentArea3">
                    <div class="graphContent">
                        问题解构能力
                    </div>
                    <div class="graphContent">
                        统筹规划能力
                    </div>
                </div>

                <div class="graphContentArea3">
                    <div class="graphContent">
                        蓝桥杯
                    </div>
                    <div class="graphContent">
                        全国青少年信息学奥林匹克竞赛
                    </div>
                    <div class="graphContent">
                        CSP、NOIP、USACO等
                    </div>
                </div>
            </div>
      </div>

<!--      <audio id="myAudio" autoplay  src="../assets/audios/typeWritter.mp3">-->
<!--          不支持audio标签-->
<!--      </audio>-->
  </div>
</template>

<script>
import ThreeJs from "@/js/ThreeJS";
export default {
  name: 'HomeView',
  components: {

  },
    data(){
      return {
          canvas1:null,
          canvas2:null,
          count1:0,
          count2:0,
          points1:[],
          points2:[],
          selectedPoints1:[],
          selectedPoints2:[],
          context1:null,
          context2:null,
          app:null,
          scene:null,
          camera:null,
          renderer:null,
          educationBeliefItemClassList:["educationBeliefItem","educationBeliefItem","educationBeliefItem"],
          isShowBelifDetailsList:[false,false,false],
          navItemList:["首页","产品","开源项目","关于我们","加入我们"],
          navItemClasses:["navItem","navItem","navItem","navItem","navItem"],
          tip1:"给每一个想要学习编程的孩子提供一个优质学习平台，引领未来无限可能",
          streamShowTipsId:0,
          tumaStyle:"tuma",
          isShowSignificanceTip:false,
          streamSignificanceTipsId:0,
          significanceTips:"",
          significanceTipTotal:"在AI大模型时代，掌握至少一门编程语言以及应用场景是十分有必要的。通过让孩子学习编程能很好地激发孩子的创造力以及对未知世界的探索\n" +
              "              能力，让孩子在不断尝试和思考学习知识以及提升综合素质能力。同时掌握一门编程语言也能让孩子使用编程思想和编程方法去解决实际生活中的一些问题，提升学习效率。",

      }
    },
    mounted(){
        this.canvas1=this.$refs.canvas1;
        this.context1=this.canvas1.getContext("2d");
        this.context1.fillStyle="#4d89eb";
        this.context1.strokeStyle="#4d89eb";
        this.context1.lineWidth=1;

        this.canvas2=this.$refs.canvas2;
        this.context2=this.canvas2.getContext("2d");
        this.context2.fillStyle="#4d89eb";
        this.context2.strokeStyle="#4d89eb";
        this.context2.lineWidth=1;

        this.points1=[];
        this.selectedPoints1=[];
        this.points2=[];
        this.selectedPoints2=[];
        this.randomPoint1();
        this.randomPoint2();
        this.threeJSInit();
        setTimeout(()=>{
            this.tumaStyle="tumaAnim";
        },1500);
        this.streamShowTips();
    },
    methods:{


        drawCircle1(x,y,radius){
            this.context1.beginPath();
            this.context1.arc(x,y,radius,0,Math.PI*2,true);
            this.context1.closePath();
        },

        drawCircle2(x,y,radius){
            this.context2.beginPath();
            this.context2.arc(x,y,radius,0,Math.PI*2,true);
            this.context2.closePath();
        },

        getDistance(x1,y1,x2,y2){
            return Math.sqrt((x1-x2)*(x1-x2)+(y1-y2)*(y1-y2));
        },

        getMinDistance1(x,y){
            let result=[];
            for(let i=0;i<this.points1.length;i++){
                let t=this.getDistance(x,y,this.points1[i].x,this.points1[i].y);
                result.push({idx:i,d:t});
            }
            result.sort((a,b)=>{
                return a.d-b.d;
            })
            let res=[];
            for(let i=0;i<5;i++){
                res.push(result[i]);
            }
            return res;
        },

        getMinDistance2(x,y){
            let result=[];
            for(let i=0;i<this.points2.length;i++){
                let t=this.getDistance(x,y,this.points2[i].x,this.points2[i].y);
                result.push({idx:i,d:t});
            }
            result.sort((a,b)=>{
                return a.d-b.d;
            })
            let res=[];
            for(let i=0;i<5;i++){
                res.push(result[i]);
            }
            return res;
        },

        checkIsInPoints1(x,y){
            for(let item of this.selectedPoints1){
                if(item.x==x && item.y==y){
                    return true;
                }
            }
            return false;
        },

        checkIsInPoints2(x,y){
            for(let item of this.selectedPoints2){
                if(item.x==x && item.y==y){
                    return true;
                }
            }
            return false;
        },

        drawLine1(){
            for(let i=0;i<5;i++){
                let idx1=parseInt(Math.random()*this.points1.length);
                while(this.checkIsInPoints1(this.points1[idx1].x,this.points1[idx1].y)){
                    idx1=parseInt(Math.random()*this.points1.length);
                }
                //console.log("idx1:",points[idx1])
                let res=this.getMinDistance1(this.points1[idx1].x,this.points1[idx1].y)
                //console.log("Res:",res);
                for(let j=0;j<5;j++){
                    this.context1.moveTo(this.points1[idx1].x,this.points1[idx1].y);
                    this.context1.lineTo(this.points1[res[j].idx].x,this.points1[res[j].idx].y);
                    this.selectedPoints1.push(this.points1[res[j].idx]);
                    this.context1.stroke();
                }
                this.selectedPoints1.push(this.points1[idx1]);
                //console.log("---------------------------")
            }
            console.log('this.selectedPoints1:',this.selectedPoints1);
        },
        drawLine2(){
            for(let i=0;i<5;i++){
                let idx1=parseInt(Math.random()*this.points2.length);
                while(this.checkIsInPoints2(this.points2[idx1].x,this.points2[idx1].y)){
                    idx1=parseInt(Math.random()*this.points2.length);
                }
                //console.log("idx1:",points[idx1])
                let res=this.getMinDistance2(this.points2[idx1].x,this.points2[idx1].y)
                //console.log("Res:",res);
                for(let j=0;j<5;j++){
                    this.context2.moveTo(this.points2[idx1].x,this.points2[idx1].y);
                    this.context2.lineTo(this.points2[res[j].idx].x,this.points2[res[j].idx].y);
                    this.selectedPoints2.push(this.points2[res[j].idx]);
                    this.context2.stroke();
                }
                this.selectedPoints2.push(this.points2[idx1]);
                //console.log("---------------------------")
            }
            console.log('this.selectedPoints2:',this.selectedPoints2);
        },
        randomPoint1(){
            for(let i=0;i<40;i++){
                let x=Math.random()*this.canvas1.width;
                let y=Math.random()*this.canvas1.height;
                this.points1.push({x,y});
                this.drawCircle1(x,y,2);
                this.context1.fill();
            }

            this.drawLine1();
        },
        randomPoint2(){
            for(let i=0;i<40;i++){
                let x=Math.random()*this.canvas2.width;
                let y=Math.random()*this.canvas2.height;
                this.points2.push({x,y});
                this.drawCircle2(x,y,2);
                this.context2.fill();
            }
            this.drawLine2();
        },

        draw1(){
            if(this.count1%120==0){
                this.context1.clearRect(0,0,this.canvas1.width,this.canvas1.height);
                //context.rotate(Math.PI/2);
                this.selectedPoints1=[];
                for(let i=0;i<40;i++){
                    this.drawCircle1(this.points1[i].x,this.points1[i].y,2);
                    this.context1.fill();
                }

                this.drawLine();
            }

            this.count1++;
            //requestAnimationFrame(this.draw)
        },
        draw2(){
            if(this.count2%120==0){
                this.context2.clearRect(0,0,this.canvas2.width,this.canvas2.height);
                //context.rotate(Math.PI/2);
                this.selectedPoints2=[];
                for(let i=0;i<40;i++){
                    this.drawCircle1(this.points2[i].x,this.points2[i].y,2);
                    this.context2.fill();
                }

                this.drawLine();
            }

            this.count2++;
            //requestAnimationFrame(this.draw)
        },
        async threeJSInit(){

            this.app=new ThreeJs("threejs");
            this.app.initThree();
            this.app.initController();
            //this.app.initHelper();
            this.renderer=this.app.renderer;
            this.scene=this.app.scene;
            this.camera=this.app.camera;
            this.controls=this.app.controls;
            this.app.render(()=>{
                this.renderer.render(this.scene,this.camera)
            })
        },
        showSignificanceTip(){
            if(!this.isShowSignificanceTip){
                this.streamShowSignificanceTips();
                this.isShowSignificanceTip=true;
            }


        },
      // showStreamTip(){
      //     const audio=document.getElementById("myAudio");
      //     audio.play();
      //
      // },
        hideBeliefDetails(){
            this.educationBeliefItemClassList=["educationBeliefItem","educationBeliefItem","educationBeliefItem"];
            this.isShowBelifDetailsList=[false,false,false];
        },
        showBeliefDetails(index){
            this.educationBeliefItemClassList[index]="educationBeliefItemDetails";
            for(let i=0;i<this.educationBeliefItemClassList.length;i++){
                if(i!=index){
                    this.educationBeliefItemClassList[i]="educationBeliefItem";
                }
            }
            this.isShowBelifDetailsList[index]=true;
            for(let i=0;i<this.isShowBelifDetailsList.length;i++){
                if(i!=index){
                    this.isShowBelifDetailsList[i]=false;
                }
            }
            this.$forceUpdate();
        },
      streamShowTips(){
          let i=0;
          let s="";
          let tip=this.tip1;
          this.streamShowTipsId=setInterval(()=>{
              s+=tip[i];
              this.tip1=s;
              if(i==tip.length-1){
                  clearInterval(this.streamShowTipsId);
              }
              i++;
          },200);
      },
        streamShowSignificanceTips(){
            let i=0;
            let s="";
            let tip=this.significanceTipTotal;

            this.streamSignificanceTipsId=setInterval(()=>{
                s+=tip[i];

                this.significanceTips=s;
                if(i==tip.length-1){
                    clearInterval(this.streamSignificanceTipsId);
                }
                i++;
            },50);
        },

        showNavBorderBottom(index){
            for(let i=0;i<this.navItemClasses.length;i++){
                if(i==index){
                    this.navItemClasses[i]="navItemSelected";
                }else{
                    this.navItemClasses[i]="navItem";
                }
            }
            this.$forceUpdate();
        },
        hideNavBorderBottom(){
            this.navItemClasses=["navItem","navItem","navItem","navItem","navItem"];
        }
    }
}
</script>

<style lang="less" scoped>
    @keyframes jump {
        0% { transform:translateY(-90px); }
        25% { transform:translateY(-50px);  }
        50% { transform:translateY(0);  }
        70% { transform:translateY(-10px);  }
        80% { transform:translateY(-5px);  }
        90% { transform:translateY(-2px);  }
        100% { transform:translateY(0);  }
    }

    @keyframes changeSize {
        0% { position:fixed;left:38px;top:90px;font-size:32px; }
        10% { position:fixed;left:600px;top:260px;font-size:100px;}
        50% {position:fixed; left:600px;top:260px;font-size:120px;}
        90% { position:fixed;left:600px;top:260px;font-size:100px;}
        100% { position:absolute;left:-65px;top:0;font-size:32px; }
    }

    @keyframes changeOpacity {

        0% { opacity: 0;}
        100% { opacity: 1;  }
    }

    #codeCountry{
        position: absolute;
        left:194px;
        top:0;
        animation: jump 1.5s 1;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
    }
  .main{
    width:100%;
    height: 100vh;
      position: relative;

      #threejs{
          width:100%;
          height:70px;
      }
    .banner{
      width:100%;
      height:258px;
      background: linear-gradient(290.96deg, #0064FF 0%, #0439A4 99.8%);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .left{
            flex:3;
            height:100%;

            #canvas1{
                height:258px;
                position: absolute;
                inset: 0;
            }
            .top{
                width:100%;
                height:80px;
                display:flex;
                justify-content: flex-start;
                align-items: center;
                .logo{
                    width:70px;
                    height:82px;
                    margin-left:90px;
                    margin-right:10px;
                    img{
                        width:100%;
                        height:100%;
                    }
                }
                .navs{
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size:14px;
                    color:#fff;
                    width:600px;
                    margin-top:3px;
                    .navItem{
                        padding-bottom:17px;
                        cursor: pointer;
                    }
                    .navItemSelected{
                        cursor: pointer;
                        padding-bottom:16px;
                        border-bottom:1px solid #fff;

                    }
                }
            }
            .introduce1{
                font-size:32px;
                color:#fff;
                font-weight: 800;
                margin-left:103px;
                margin-top:10px;
                position: relative;
                .emphasize{
                    color:#2bfabe;
                }
                .tuma{
                    color:#ea9d2d;
                    position: absolute;
                    top:0;
                    left:-65px;
                }
                .tumaAnim{
                    position: absolute;
                    top:0;
                    left:-65px;
                    color:#ea9d2d;
                    animation: changeSize 3s 1;
                    animation-timing-function: ease;
                    animation-fill-mode: forwards;
                    z-index:100;
                }
            }
            .tip1{
                font-size:12px;
                color:#fff;
                margin-left:227px;
                margin-top:40px;
            }
            .effectiveList{
                display:flex;
                justify-content: space-between;
                align-items: center;
                width:326px;
                margin-left:380px;
                margin-top:26px;
                .effectiveItem{
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    .effectiveImg{
                        width:32px;
                        height:32px;
                        margin-right:7px;
                        img{
                            width:100%;
                            height:100%;
                        }
                    }
                    .effectiveTxt{
                        font-size:10px;
                        color:#fff;
                    }


                }
            }
        }

        .right{
            flex:2;
            height:calc(100% - 14px * 2);
            padding:14px 0;
            img{
                width:100%;
                height:100%;
            }
        }


    }
    .title{
        font-size:20px;
        font-weight:700;
        width:100%;
        text-align:center;
        margin:22px 0;
    }
      .title2{
          font-size:20px;
          font-weight:700;
          width:100%;
          text-align:center;
          margin-top:28px;
      }
      .educationBelief{
          width:calc(100% - 20px * 2);
          display: flex;
          justify-content: center;
          align-items: center;
          padding:0 20px;
          .educationBeliefItem{
              flex:1;
              height:114px;
              padding:0 10px;
              background-color:#E9F3FF;
              margin:0 20px;
              display:flex;
              justify-content: flex-start;
              align-items: center;
              animation: changeOpacity 1s 1;
              animation-timing-function: ease;
              animation-fill-mode: forwards;
              z-index:10;
              .beliefImg{
                  width:50px;
                  height:50px;
                  margin-left:45px;
                  margin-right:86px;
                  img{
                      width:100%;
                      height:100%;
                  }
              }
              .beliefName{
                  font-size:22px;
                  font-weight:800;
                  color:#4E84FE;
                  text-align: center;
                  line-height:114px;
              }

          }

          .educationBeliefItemDetails{
              flex:1;
              height:114px;
              background-color:rgb(78, 132, 254);
              padding:0 10px;
              margin:0 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              color:#fff;
              font-size: 12px;
              line-height:22px;
          }
      }
      .significance{
          width:calc(100% - 37px * 2);
          margin:25px auto;
          height:170px;
          position: relative;
          background: linear-gradient(290.96deg, #7793FA 0%, #0357FF 99.8%);
          #canvas2{
              height:170px;
              width:80%;
              position: absolute;
              inset: 0;
              pointer-events: none;
          }
          .significanceMain{
              width:100%;
              height:120px;
              display: flex;
              justify-content: center;
              align-items: flex-start;
          }
          .significanceLeft{
              flex:1;
              padding:30px;
              .significanceTitle{
                  font-size:14px;
                  color:#fff;
                  margin-left:80px;
                  font-weight:800;
                  .significanceEmphasize{
                      color:#ea9d2d;
                      margin:15px 0;
                  }
              }
              .significanceContent{
                  font-size:20px;
                  color:#fff;
                  margin-top:28px;
                  margin-left:80px;
                  font-weight:800;
              }
          }
          .significanceRight{
              flex:1;
              .astronaut{
                  width:180px;
                  height:180px;
                  margin-left:auto;
                  margin-right:30px;
                  img{
                      width:100%;
                      height:100%;
                  }
              }
          }
        .significanceTip{
            font-size:12px;
            color:#fff;
            line-height: 20px;
            padding:0 30px;
            width:80%;
        }

      }

      .courseList{
          width:calc(100% - 70px * 2);
          padding:0 70px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          .graph{
              flex:1;
              border-radius:10px 10px 0 0;
              .graphTitle1{
                  background-color:#f8eadc;
                  color:#fe9b0d;
                  font-weight:800;
                  width:100%;
                  border-radius:10px 10px 0 0;
                  text-align: center;
                  height:45px;
                  line-height:45px;

              }

              .graphContentArea1{
                  border-top:1px solid #fff;
                  border-right:1px solid #fff;
                  background-color: #fe9b0d;
                  padding-top:8px;
                  .graphContent{

                      width:100%;
                      text-align: center;
                      color:#fff;
                      font-size:12px;
                      padding-bottom:8px;
                  }
              }


          }
          .python{
              flex:1;
              background-color:#1e7dff;
              border-radius:10px 10px 0 0;
              .graphTitle2{
                  background-color:#a6cbff;
                  border-radius:10px 10px 0 0;
                  color:#1e7dff;
                  font-weight:800;
                  width:100%;
                  text-align: center;
                  height:75px;
                  line-height:75px;
              }

              .graphContentArea2{
                  border-top:1px solid #fff;
                  border-right:1px solid #fff;
                  background-color: #1e7dff;
                  padding-top:8px;
                  .graphContent{

                      width:100%;
                      text-align: center;
                      color:#fff;
                      font-size:12px;
                      padding-bottom:8px;
                  }
              }
          }
          .cpp{
              flex:1;
              background-color:#ff5000;
              border-radius:10px 10px 0 0;
              .graphTitle3{
                  border-radius:10px 10px 0 0;
                  background-color:#f7d6ca;
                  color:#ff5000;
                  font-weight:800;
                  width:100%;
                  text-align: center;
                  height:120px;
                  line-height:120px;
              }

              .graphContentArea3{
                  border-top:1px solid #fff;
                  background-color: #ff5000;
                  padding-top:8px;
                  .graphContent{

                      width:100%;
                      text-align: center;
                      color:#fff;
                      font-size:12px;
                      padding-bottom:8px;
                  }
              }
          }
      }

  }
</style>
