<script>
import ThreeJs from "@/js/ThreeJS";
export default {
    name: 'productView',
    components: {

    },
    data(){
        return{
            app:null,
            scene:null,
            camera:null,
            renderer:null
        }
    },
    methods:{
        async init(){

            this.app=new ThreeJs("threejs");
            this.app.initThree();
            this.app.initController();
            //this.app.initHelper();
            this.renderer=this.app.renderer;
            this.scene=this.app.scene;
            this.camera=this.app.camera;
            this.controls=this.app.controls;
            this.app.render(()=>{
                this.renderer.render(this.scene,this.camera)
            })
            //this.animate();
        },
        animate() {
            requestAnimationFrame(this.animate);
            this.renderer.render(this.scene, this.camera);
            this.controls.update();
        }
    },
    mounted() {
        setTimeout(()=>{
            this.init();
        },200);

    }
}

</script>

<template>
    <div class="main">
        <div class="test">
            Python模型示例
        </div>
        <div id="threejs"></div>
    </div>
</template>

<style scoped lang="less">
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* 防止出现滚动条 */
}
.test{
    font-size:38px;
    font-weight:800;
    text-align: center;
    width:100%;
    margin-top:60px;
    font-family: tip,sans-serif;
    color: red;
}
.main{
    width:100%;
}
#threejs{
    width:100%;
    height:530px;
}
@font-face {
    font-family: "tip";
    src: url("../../public/font/ZiHunDaHei-Heavy(ShangYongXuShouQuan)-2.ttf");
}
</style>