import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import {CSS3DRenderer} from "three/examples/jsm/renderers/CSS3DRenderer"
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';



export default class ThreeJs{

    constructor(id){
        //根据传入的id，去获取他的dom节点，用来加载三维模型
        this.id=id;
        this.dom=document.getElementById(id);
    }

    /**
     * @name 初始化控制器
     * @author shaoqunchao
     * @date 2024/1/18 13:05
     * @description 轨道控制器，可以使得相机围绕目标进行轨道运动
     * @param enableZoom 设置控制器是否可以缩放，默认为true。
     * @param autoRotate 设置是否自动旋转，默认为false。
     * @param enableDamping 设置控制器阻尼，让控制器更有真实效果，默认为false。
     * @param dampingFactor 设置控制器阻尼系数，让控制器更有真实效果，默认为0。
     * @param minDistance 设置控制器最小距离，默认为1。
     * @param maxDistance 设置控制器最大距离，默认为300。
     * @param minAzimuthAngle 设置控制器最小旋转角度，默认为0。
     * @update 2024/1/18 13:05
     **/
    initController(enableZoom=true,autoRotate=false,enableDamping=false,dampingFactor=0,minDistance=1,maxDistance=1000,minAzimuthAngle=0){
        let width=this.dom.offsetWidth;
        let height=this.dom.offsetHeight;
        //这个Renderer是用来运行3维指标，相当于一个html页面去嵌入至模型中。
        this.labelRenderer=new CSS3DRenderer();
        this.labelRenderer.setSize(width,height);
        //用来调整html页面的样式，让他围绕模型点位时，不会有偏移。
        this.labelRenderer.domElement.style.position='absolute';
        this.labelRenderer.domElement.style.top=0;
        this.labelRenderer.domElement.style.pointerEvents="none";
        this.dom.appendChild(this.labelRenderer.domElement);


        this.controller=new OrbitControls(this.camera,this.renderer.domElement);
        //设置控制器是否可以缩放
        this.controller.enableZoom=enableZoom;
        //设置是否可以旋转
        this.controller.autoRotate=autoRotate;
        //设置控制器阻尼效果，让控制器有真实的效果，
        this.controller.enableDamping=enableDamping;
        //设置阻尼控制器的系数
        this.controller.dampingFactor=dampingFactor;
        //设置控制器放大的最小距离
        this.controller.minDistance=minDistance;
        //设置控制器缩小的最大距离
        this.controller.maxDistance=maxDistance;
        //设置控制器最小旋转角度
        this.controller.minAzimuthAngle=minAzimuthAngle;
    }

    initThree(){
        //这个初始化的是场景
        //这个初始化的是场景
        this.scene= new THREE.Scene();
        this.width=this.dom.offsetWidth;
        this.height=this.dom.offsetHeight;
        this.camera= new THREE.PerspectiveCamera(45,this.width/this.height,1,1000);

        this.camera.position.set(50,80,20);
        this.camera.lookAt(0,0,0);

        //追加webGL的渲染器，他是用来调用浏览器的GPU，去进行实时渲染（前提浏览器已经支持GPU）
        //antialias:是否开启锯齿，alpha：是否开启透明，logarithmicDepthBuffer：是否开启对数深度缓存
        this.renderer=new THREE.WebGLRenderer({antialias:true,alpha:true,logarithmicDepthBuffer:true})
        // 设置渲染器的像素比
        this.renderer.setPixelRatio(window.devicePixelRatio);
        //渲染器的输出颜色
        this.renderer.outputEncoding=THREE.sRGBEncoding;
        //设置渲染器的大小
        this.renderer.setSize(this.width,this.height);
        //将渲染器的dom元素，添加至我们div的dom元素中
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.dom.append(this.renderer.domElement);

        // 监听浏览器大小，去更新相机的矩阵
        window.addEventListener('resize',()=>{
            // 更新相机的宽高比
            this.camera.aspect=this.dom.offsetWidth/this.dom.offsetHeight;
            this.camera.updateProjectionMatrix();
            //更新渲染器的大小
            this.renderer.setSize(this.dom.offsetWidth,this.dom.offsetHeight);
            if(this.cssRenderer){
                this.cssRenderer.setSize(this.dom.offsetWidth,this.dom.offsetHeight);
            }
        })

        // 添加光源
        const light = new THREE.DirectionalLight(0xffffff, 5);
        light.position.set(50, 80, 20);
        this.scene.add(light);
        // 创建LoadingManager
        const loadingManager1 = new THREE.LoadingManager();
        const loadingManager2 = new THREE.LoadingManager();



        loadingManager1.onStart = (url, loaded, total) => console.log(`开始加载贴图 ${url}，已加载 ${loaded} / ${total}`);
        loadingManager1.onLoad = () =>{
            console.log("贴图加载完成");
            const loader = new FBXLoader(loadingManager2);
            loader.load('/models/Py(4).fbx', (object) => {
                console.log("模型加载完成")
                object.scale.set(0.5,0.5,0.5);
                object.rotation.set(Math.PI / 6, Math.PI / 3.4, -Math.PI / 6, "XZY");
                const material = new THREE.MeshStandardMaterial({
                    map: baseTexture,
                    metalnessMap: metallicTextureMapTexture,
                    roughnessMap: roughnessTexture,
                    normalMap: normalMapTexture,
                    heightMap: heightTexture,
                    aoMap:aoTexture,
                    metalness:1.0
                });


                // 应用贴图到模型的材质
                object.traverse(child => {

                    if (child.isMesh) {
                        child.material=material;
                        console.log(child.material.metalness)
                        // child.material.map = baseTexture;
                        // child.material.normalmap = normalMapTexture;
                        // console.log(child.material.map)
                        // 如果模型有多个材质层，可以遍历materials并应用贴图
                        // child.material.materials.forEach(mat => mat.map = map);

                    }

                    this.scene.add(object);
                });

                // loader.load('/models/character.fbx', (object) => {
                //     this.scene.add(object);
                // });

                // 渲染场景
                let animate=() =>{
                    requestAnimationFrame(animate);
                    this.renderer.render(this.scene, this.camera);
                }

                animate();
            }, (progress) => {
                console.log(`Loading: ${progress.loaded / progress.total * 100}%`);
            }, (error) => {
                console.error(error);
            });
        }
        loadingManager1.onProgress = (url, loaded, total) => console.log(`加载模型 ${url}，进度 ${loaded} / ${total}`);
        loadingManager1.onError = (url) => console.error(`模型加载错误 ${url}`);
        // 设置贴图加载器
        const textureLoader = new THREE.TextureLoader(loadingManager1);
// 加载贴图
        const baseTexture = textureLoader.load('/texture/New_Graph_basecolor.png');
        const normalMapTexture = textureLoader.load('/texture/New_Graph_normal.png');
        const metallicTextureMapTexture = textureLoader.load('/texture/New_Graph_metallic.png');
        const roughnessTexture = textureLoader.load('/texture/New_Graph_roughness.png');
        const heightTexture = textureLoader.load('/texture/New_Graph_height.png');
        const aoTexture = textureLoader.load('/texture/New_Graph_ambientocclusion.png');




    }

    /**
     * @name
     * @author shaoqunchao
     * @date 2024/3/5 10:21
     * @description 初始化X，Y，Z轴的扶助对象
     * @update 2024/3/5 10:21
     **/
    initHelper(helperSize=1000){
        this.scene.add(new THREE.AxesHelper(helperSize));
    }


    render(callback){
        callback();
        requestAnimationFrame(()=>this.render(callback));
    }



}
